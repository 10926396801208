
import { Component, Mixins } from "vue-property-decorator";
import { adminExport } from "@/api/mutations/export";
import { exportCount } from "@/api/queries/export";
import LoaderMixin from "@/mixins/LoaderMixin";
import { ExportPaymentType, ExportType } from "@/@types/enums/admin/Export";
import { adminFileExported } from "@/api/subscriptions/export";

@Component({
  components: {
    BaseExportCard: () => import("@/components/BaseExportCard.vue"),
  },
})
export default class Overview extends Mixins(LoaderMixin) {
  private exportVariables(exportFile: string, payment: string) {
    return { exportFile, payment };
  }

  private get exportMutation() {
    return adminExport;
  }

  private get exportQuery() {
    return exportCount;
  }

  private get exportSubscription() {
    return adminFileExported;
  }

  private incubation(exportFile: string) {
    return (
      exportFile === ExportType.merchantDetails ||
      exportFile === ExportType.merchantAutomations ||
      exportFile === ExportType.subscriberExport ||
      exportFile === ExportType.merchant ||
      this.merchantProducts(exportFile) ||
      this.orderExports(exportFile)
    );
  }

  private merchantProducts(exportFile: string) {
    return exportFile === ExportType.merchantProducts;
  }

  private orderExports(exportFile: string) {
    return (
      exportFile === ExportType.orderAll ||
      exportFile === ExportType.orderPayment
    );
  }

  private get dataExports() {
    return [
      {
        title: "Merchant",
        exportType: ExportType.merchant,
        paymentType: null,
      },
      {
        title: "Order Transactions",
        exportType: ExportType.orderAll,
        paymentType: null,
      },
      {
        title: "Disbursements",
        exportType: ExportType.disbursement,
        paymentType: null,
      },
      {
        title: "Inquiry Details",
        exportType: ExportType.inquiryRequest,
        paymentType: null,
      },
      {
        title: "GCash Payments",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.gcash,
      },
      {
        title: "BPI Payments",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.bpi,
      },
      {
        title: "PayMaya Payments",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.maya,
      },
      {
        title: "Paymongo Payments",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.paymongo,
      },
      {
        title: "Billease Payments",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.billease,
      },
      {
        title: "COD by Ninja Van",
        exportType: ExportType.orderPayment,
        paymentType: ExportPaymentType.ninjavan,
      },
      {
        title: "GLife Subscription",
        exportType: ExportType.glifeSubscription,
        paymentType: null,
      },
      {
        title: "Merchant Details",
        exportType: ExportType.merchantDetails,
        paymentType: null,
      },

      {
        title: "Merchant Products",
        exportType: ExportType.merchantProducts,
        paymentType: null,
      },

      {
        title: "Merchant Automations",
        exportType: ExportType.merchantAutomations,
        paymentType: null,
      },

      {
        title: "Gen AI",
        exportType: ExportType.genai,
        paymentType: null,
      },

      {
        title: "Active Subscribers",
        exportType: ExportType.subscriberExport,
        paymentType: null,
      },
    ];
  }
}
